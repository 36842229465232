import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import useApplicantViewContext from "../useApplicantViewContext";
import Loading from "customHooks/useCompleoForm/helpers/Loading";
import { Collapse, ListItemSecondaryAction, Paper } from "@material-ui/core";
import _ from "lodash";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ButtonIconTooltipCompleo from "Components/ButtonIconTooltipCompleo";
import CompleoAvatar from "Pages/_General/CompleoAvatar";
import { handleEvaluationListData } from "./ApplicantRatingHelper";
import { useAuthState } from "_ReactContext/AuthContext";
import CompleoUserName from "Pages/_General/CompleoUserName";
import CompleoCompanyRating from "Pages/_General/CompleoCompanyRating";
import ApplicantScorecardView from "./ApplicantScorecardView";
import { BarChart, ShowChart } from "@material-ui/icons";
import ModalCompareEvaluationsGraph from "./ModalCompareEvaluationsGraph";
import ModalCompareScorecardGraph from "./ModalCompareScorecardGraph";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // maxWidth: "36ch",
            backgroundColor: theme.palette.background.paper
        },
        inline: {
            display: "inline"
        },
        paperContainer: {
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        avatarsmall: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: 12
        }
    })
);

function createMarkup(value: string) {
    return { __html: value };
}

interface IProps {
    t: any;
}
const ApplicantRatingList = (props: IProps) => {
    const classes = useStyles();
    const { t } = props;
    const [threadOpen, setThreadOpen] = React.useState<Compleo.IObject>({});
    const { company, user } = useAuthState();
    const [openGraph, setOpenGraph] = React.useState<{
        open: boolean;
        jobId: string;
    }>({ open: false, jobId: "" });
    const [openScorecardGraph, setOpenScorecardGraph] = React.useState<{
        open: boolean;
        jobId: string;
    }>({ open: false, jobId: "" });

    const [data] = useApplicantViewContext();

    const dataEvaluation = handleEvaluationListData({
        data: data.applicantData?.Evaluations || [],
        currentUser: user || ""
    });

    const getJobTitle = (jobId: string) => {
        return (data.applicantData.Jobs || []).filter(
            (item: Compleo.IObject) => item.JobId === jobId
        )[0]?.JobTitle;
    };

    const handleClick = (threadPK: string) => {
        const localThreadOpen = _.cloneDeep(threadOpen);
        localThreadOpen[threadPK] = !(localThreadOpen[threadPK] === true);
        setThreadOpen(localThreadOpen);
    };
    let lastJobId = "";
    console.log("dataEvaluation", dataEvaluation);
    console.log("dataEvaluation allJobs", data.applicantData);
    if (dataEvaluation.length) {
        return (
            <Paper className={classes.paperContainer}>
                <List className={classes.root}>
                    {dataEvaluation.map((item: Compleo.IObject) => {
                        const threadId = `${item.jobId}|${item.evaluator}`;
                        const open = threadOpen[threadId] === true;
                        const jobIdChanged = lastJobId !== item.jobId;
                        lastJobId = item.jobId;

                        const averageGlobalEvaluation =
                            data.applicantData?.EvaluationsSummaryGlobal || 0;
                        const averageJobEvaluation =
                            (
                                data.applicantData?.EvaluationsAllJobs || []
                            ).filter(
                                (localitem: Compleo.IObject) =>
                                    localitem.jobId === item.jobId
                            )?.[0]?.value || 0;
                        const headerJobEvaluation =
                            item.jobId === "GLOBAL"
                                ? averageGlobalEvaluation
                                : averageJobEvaluation;

                        const hasDetails =
                            item.comment || (item.scorecard || []).length > 0;

                        return (
                            <React.Fragment key={threadId}>
                                {jobIdChanged && (
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    <strong>
                                                        {item.jobId === "GLOBAL"
                                                            ? t("generalRating")
                                                            : `${t(
                                                                  "job"
                                                              )}: ${getJobTitle(
                                                                  item.jobId
                                                              )}`}
                                                    </strong>
                                                </Typography>
                                            }
                                            secondary={
                                                <CompleoCompanyRating
                                                    evaluationValue={
                                                        headerJobEvaluation
                                                    }
                                                    size="medium"
                                                    showTextValue
                                                />
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            {item.jobId !== "GLOBAL" && (
                                                <ButtonIconTooltipCompleo
                                                    label={t("scorecard")}
                                                    onClick={() =>
                                                        setOpenScorecardGraph({
                                                            open: true,
                                                            jobId: item.jobId
                                                        })
                                                    }
                                                >
                                                    <ShowChart />
                                                </ButtonIconTooltipCompleo>
                                            )}
                                            <ButtonIconTooltipCompleo
                                                label={t("charts")}
                                                onClick={() =>
                                                    setOpenGraph({
                                                        open: true,
                                                        jobId: item.jobId
                                                    })
                                                }
                                            >
                                                <BarChart />
                                            </ButtonIconTooltipCompleo>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <CompleoAvatar
                                            idParams={{
                                                email: item.evaluator
                                            }}
                                            type="user"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <CompleoUserName
                                                userEmail={item.evaluator}
                                                textNotFound={`${
                                                    item.evaluator
                                                } (${t("externalEvaluation")})`}
                                            />
                                        }
                                        secondary={
                                            <CompleoCompanyRating
                                                evaluationValue={
                                                    item.generalEvaluation || 0
                                                }
                                                size="small"
                                                showTextValue
                                            />
                                        }
                                    />
                                    {hasDetails && (
                                        <ListItemSecondaryAction>
                                            <ButtonIconTooltipCompleo
                                                label={t("showMessagesExpand")}
                                                onClick={() =>
                                                    handleClick(threadId)
                                                }
                                            >
                                                {open ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </ButtonIconTooltipCompleo>
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {item.comment && (
                                        <ListItem className={classes.nested}>
                                            <ListItemText
                                                primary={
                                                    <strong>{`${t(
                                                        "comments"
                                                    )}:`}</strong>
                                                }
                                                secondary={
                                                    <Typography>
                                                        <div
                                                            dangerouslySetInnerHTML={createMarkup(
                                                                item.comment
                                                            )}
                                                        />
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    )}
                                    {(item.scorecard || []).length > 0 && (
                                        <ListItem className={classes.nested}>
                                            <ApplicantScorecardView
                                                jobId={item.jobId}
                                                scorecard={item.scorecard}
                                                t={t}
                                            />
                                        </ListItem>
                                    )}
                                </Collapse>
                                <Divider component="li" />
                            </React.Fragment>
                        );
                    })}
                </List>
                <ModalCompareEvaluationsGraph
                    open={openGraph.open}
                    jobId={openGraph.jobId}
                    dataEvaluation={dataEvaluation}
                    handleClose={() => {
                        setOpenGraph({ open: false, jobId: "" });
                    }}
                    t={t}
                />
                <ModalCompareScorecardGraph
                    open={openScorecardGraph.open}
                    jobId={openScorecardGraph.jobId}
                    dataEvaluation={dataEvaluation}
                    handleClose={() => {
                        setOpenScorecardGraph({ open: false, jobId: "" });
                    }}
                    t={t}
                />
            </Paper>
        );
    } else if (!data.ready) {
        return <Loading />;
    } else {
        return null;
    }
};

export default ApplicantRatingList;
