import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoForm from "customHooks/useCompleoReactHookForm";
import { apiDirectCall, ApiStateType, useApiCache } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import _ from "lodash";
import { useQueryClient } from "react-query";
import { useAuthState } from "_ReactContext/AuthContext";
import { Box, Typography } from "@material-ui/core";
import ApplicantEmailRecipientsTags, {
    IRecipientsTags
} from "Pages/Email/ApplicantEmailRecipientsTags";

interface IModalEditMail {
    open: boolean;
    handleClose: () => void;
    scheduleId: string;
    invalidateQuery?: string[];
    applicantPreferredLanguage?: Compleo.IObject;
}

const ModalEditEvent = (props: IModalEditMail) => {
    const {
        open,
        handleClose,
        scheduleId,
        invalidateQuery = [],
        applicantPreferredLanguage
    } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);
    const queryClient = useQueryClient();
    const { company } = useAuthState();

    const module = "SCHEDULEEDIT";
    const [metadata] = useGetMetadata(module);
    // const formikRef = React.useRef(null);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const postAddressSearch = `/email/listschedule`;
    const [getScheduleSearchResult] = useApiCache(
        postAddressSearch,
        "post",
        { scheduleId: scheduleId, companyId: company.companyId },
        false
    );
    const [initialValuesObj] = useValuesFromSource(metadata, true, {});
    const dbData = (getScheduleSearchResult?.response?.data?.data || [])[0];
    const initialValues = handleInitialValues(initialValuesObj, dbData);
    if (applicantPreferredLanguage) {
        initialValues.applicantPreferredLanguage = applicantPreferredLanguage;
    }
    // console.log("dbData", dbData);
    const ready =
        readyTranslation &&
        metadata.status === "success" &&
        getScheduleSearchResult.status === "success";
    // const [jobId, setJobId] = React.useState<Compleo.IObject | null>(null);
    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "template",
            props: {
                type: dbData?.jobId
                    ? "communicationApplicantJob"
                    : "communicationApplicant"
            }
        }
    ];

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        try {
            setIsUpdating(true);
            console.log("values: ", JSON.stringify(values, null, 4));
            const postAddress = `/email/editscheduleevent`;
            await apiDirectCall(postAddress, "post", values);
            setIsUpdating(false);
            for (const queryKeyData of invalidateQuery) {
                queryClient.invalidateQueries(queryKeyData);
            }
            handleClose();
            return retorno;
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        // return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const [formCompleo, finished] = useCompleoForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        additionalFieldProperties: addProps,
        secondaryButtonFunction: handleClose
    });
    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {finished ? t("a_FeatureMainDescription") : ""}
            </DialogTitle>
            <DialogContent>
                {finished ? (
                    <>
                        <Box marginBottom={2}>
                            <Typography variant="body2" component="span">
                                <strong>{t("recipients")}:</strong>
                            </Typography>
                            <ApplicantEmailRecipientsTags
                                recipients={
                                    (dbData?.recipients ||
                                        []) as IRecipientsTags[]
                                }
                                t={t}
                                applicantEmail=""
                                withoutColors={true}
                            />
                        </Box>
                        <Box>{FormReturn}</Box>
                    </>
                ) : (
                    <Loading />
                )}
            </DialogContent>
            {/* <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalClose")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={isUpdating}
                >
                    {t("ModalButton_SendEmail")}
                </Button>
            </DialogActions> */}
        </Dialog>
    );
};

const handleInitialValues = (
    initialValues: Compleo.IObject,
    data: Compleo.IObject
) => {
    const returnData = _.cloneDeep(initialValues);
    returnData.scheduleId = (data?.pk || "").split(":")[1];
    returnData.scheduleDate = {
        startDateTime: data?.startDateTime,
        endDateTime: data?.endDateTime
    };
    returnData.template = [
        {
            ...((returnData.template || [])[0] || {}),
            body: data?.body,
            location: data?.location,
            subject: data?.subject
        }
    ];
    returnData.recipients = data?.recipients || [];
    return returnData;
};

export default ModalEditEvent;
