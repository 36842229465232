import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useGetMetadata from "customHooks/useGetMetadata";
import { useTranslation } from "react-i18next";
import useValuesFromSource from "customHooks/useValuesFromSource";
import useCompleoForm, {
    useCompleoReactHookFormGetMainData
} from "customHooks/useCompleoReactHookForm";
import { apiDirectCall, ApiStateType } from "customHooks/useApi";
import FormModal from "customHooks/useCompleoReactHookForm/components/FormModal";
import Loading from "customHooks/useCompleoReactHookForm/helpers/Loading";
import _ from "lodash";
import { useQueryClient } from "react-query";

const verifyFieldsToHide = (
    listDefinition: Compleo.CustomLists.ListDefinitionType[]
) => {
    const fieldsToHide: string[] = [];
    if (
        (
            listDefinition.filter((item) => item.fieldName === "job")[0]
                ?.data || []
        ).length === 0
    ) {
        fieldsToHide.push("job");
    }
    return fieldsToHide;
};

const getDefaultRecipients = (
    defaultRecipients: Compleo.IObject[],
    listDefinition: Compleo.CustomLists.ListDefinitionType[]
) => {
    const values = defaultRecipients.map((item) => item.value);
    const listRecipients = listDefinition.filter(
        (item) => item.fieldName === "recipients"
    )[0];
    if (listRecipients) {
        const data = listRecipients.data || [];
        const defaultData = data.filter((item) => values.includes(item.value));
        return defaultData;
    }
    return [];
};
interface IModalNewMail {
    open: boolean;
    handleClose: () => void;
    listDefinition: Compleo.CustomLists.ListDefinitionType[];
    defaultRecipients?: Compleo.IObject[];
    applicantId?: string;
    invalidateQuery?: string[];
    applicantPreferredLanguage?: Compleo.IObject;
}

const ModalNewEvent = (props: IModalNewMail) => {
    const {
        open,
        handleClose,
        listDefinition = [],
        defaultRecipients = [],
        applicantId,
        invalidateQuery = [],
        applicantPreferredLanguage
    } = props;
    const [isUpdating, setIsUpdating] = React.useState(false);
    const queryClient = useQueryClient();

    const module = "SCHEDULENEW";
    const [metadata] = useGetMetadata(module);
    // const formikRef = React.useRef(null);

    const [t, i18n, readyTranslation] = useTranslation(module, {
        useSuspense: false
    });

    const defaultRecipientsValues = getDefaultRecipients(
        defaultRecipients,
        listDefinition
    );
    const [initialValues] = useValuesFromSource(metadata, true, {
        recipients: defaultRecipientsValues
    });
    if (applicantId) {
        initialValues.applicantId = applicantId;
    }
    if (applicantPreferredLanguage) {
        initialValues.applicantPreferredLanguage = applicantPreferredLanguage;
    }
    const ready = readyTranslation && metadata.status === "success";
    const reactHookFormMethods = useCompleoReactHookFormGetMainData({
        t,
        ready: ready,
        i18nV: i18n,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues
    });
    const { watch } = reactHookFormMethods.reactHookFormMethods;
    const jobId = watch("job");
    // const [jobId, setJobId] = React.useState<Compleo.IObject | null>(null);

    const addProps: Compleo.useCompleoForm.AdditionalPropertiesField[] = [
        {
            fieldName: "template",
            props: {
                type: jobId
                    ? "communicationApplicantJob"
                    : "communicationApplicant"
            }
        }
    ];

    const handleSubmit = async (values: any) => {
        const retorno: Compleo.IObject = {};
        retorno.status = 200;
        try {
            setIsUpdating(true);
            console.log("values: ", JSON.stringify(values, null, 4));
            const postAddress = `/email/sendschedule`;
            await apiDirectCall(postAddress, "post", values);
            setIsUpdating(false);
            for (const queryKeyData of invalidateQuery) {
                queryClient.invalidateQueries(queryKeyData);
            }
            handleClose();
            return retorno;
        } catch (ex) {
            setIsUpdating(false);
            return ex.response;
        }
        // return retorno;
    };
    const postReturn: ApiStateType = {
        status: "success",
        response: {},
        exception: null
    };

    const fieldsToHide = verifyFieldsToHide(listDefinition);

    const [formCompleo, finished] = useCompleoForm({
        t: t,
        ready: ready,
        i18nV: i18n,
        postMethod: handleSubmit,
        postReturn: postReturn,
        FormType: FormModal,
        metadadosRetorno: metadata,
        valuesFromSource: initialValues,
        formGroupPaperElevation: 0,
        additionalFieldProperties: addProps,
        customLists: listDefinition,
        fieldsToHide: fieldsToHide,
        secondaryButtonFunction: handleClose,
        reactHookFormMethods: reactHookFormMethods
    });
    let FormReturn: JSX.Element = <Loading />;
    if (finished && ready) {
        FormReturn = formCompleo;
    }

    return (
        <Dialog
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                {t("a_FeatureMainDescription")}
            </DialogTitle>
            <DialogContent>{FormReturn}</DialogContent>
            {/* <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    color="secondary"
                    disabled={isUpdating}
                >
                    {t("ELASTICLISTSETTINGS:GeneralModalClose")}
                </Button>
                <Button
                    onClick={() => handleSave()}
                    variant="contained"
                    color="primary"
                    disabled={isUpdating}
                >
                    {t("ModalButton_SendEmail")}
                </Button>
            </DialogActions> */}
        </Dialog>
    );
};

export default ModalNewEvent;
