import { useEffect } from "react";
import { useAuthState } from "_ReactContext/AuthContext";
import { useApiCache } from "customHooks/useApi";

const useTestTemplate = (uuid?: string) => {
    const { company } = useAuthState();

    const postTemplateList = `/test/testcompleotemplatelist`;
    const [templateListSearchResult, executeQuery] = useApiCache(
        postTemplateList,
        "post",
        {
            companyId: company.companyId,
            uuid: uuid
        },
        true,
        1000 * 60 * 5
    );
    useEffect(() => {
        if (uuid) {
            executeQuery();
        }
    }, [uuid]);

    const templateData:
        | CompleoShared.Assessments.ITestTemplate
        | undefined = (templateListSearchResult?.response?.data?.data || [])[0];

    type keysTemplate = keyof CompleoShared.Assessments.ITestTemplate;

    const fieldsToDelete: keysTemplate[] = [
        "pk",
        "skGS1pk",
        "deleted",
        "name",
        "companyId",
        "createdAt",
        "lastUpdatedAt",
        "lastUpdatedByUser",
        "createdByUser"
    ];

    if (templateData) {
        if (templateData.name) {
            templateData.modelName = templateData.name;
        }
        fieldsToDelete.forEach((field) => {
            delete templateData[field];
        });
    }
    const ready = !uuid || templateData !== undefined;
    return {
        templateData,
        ready
    };
};

export default useTestTemplate;
